import React, {useState} from 'react';
import styled from 'styled-components';
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import { theme } from "../utils/theme";




const Container = styled.div`
  /* height: 100vh; */
  width: 100%;
  background-color: ${props => props.theme.colors.dark};
  overflow-y: visible;
`

const TitleCond = styled.h5`
  font-size: 3rem;
  font-weight: 300;
  width: 200px;
  line-height: 1.1;
  color: ${props => props.theme.colors.body};
  margin-bottom: 150px;
  text-align: left;

  @media ${props => props.theme.breaks.down('md')} {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
`

const ContactSection = styled.div`
  min-height: 100vh;
  padding-top: 50px;
  text-align: center;
  /* padding-left: 80px; */

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;

    .title-cond {
      width: 90% !important;
    }
  }
`

const ContactContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  margin-top: 7rem;
  text-align: left;
  position: relative;

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;
  }
`

const Submission = styled.h3`
  color: ${props => props.theme.colors.primary};
  font-weight: 300;
`

const FixedFooter = styled.div`
  height: 100px;
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  padding-left: 40px;
  position: absolute;
  background-color: ${props => props.theme.colors.dark};
  bottom: 4rem;

  @media ${props => props.theme.breaks.down('md')} {
    bottom: 0;
    padding-left: 0;
  }
`


const SubmittedPage = ({ data }) => {


  return (
    
      <Layout pageTitle="Thank you for your submission" color={theme.colors.body}>
      <Container>
        
        <ContactSection className="contact-sec">
          <div className="title-cond" style={{width: '70%', margin: '0 auto'}}>
          <TitleCond>thank you</TitleCond>
          </div>
          <ContactContainer>

          <Submission>Your form has been submitted!</Submission>

          </ContactContainer>


          

         <FixedFooter>
          <SectionFooter color={theme.colors.body}></SectionFooter>
          
         </FixedFooter>

        </ContactSection>

        
      </Container>
      </Layout>
    
)
}

export default SubmittedPage;